import React from "react";
import ReactDOM from "react-dom";
import { Leaf } from "design_system/Icon";

(() => {
    "use strict";
    $("#link_id").mask("0000-0000-0000");
    $("._dob-l10n").mask("00/00/0000");

    const container = $("#icon-placeholder").get(0) as HTMLElement;
    if (container) {
        ReactDOM.render(<Leaf strokeWidth="1" spotColor="#C9DBFE" aria-hidden="true" />, container);
    }
})();

export {};
